<template>
  <form class="ne-main">
    <v-col v-if="nonet">
      <v-row>
        <v-col cols="12" class="d-flex justify-center text-center pt-10">
          <v-img lazy-src="../assets/nonet.png" width="200" max-width="250" src="../assets/nonet.png" cols="12"></v-img>
        </v-col>
        <v-col cols="12" class="justify-center text-center">
          <h4 cols="12">Connect to the internet</h4>
          <p>You're offline. Check your connection</p>
          <v-btn text color="primary" @click="$router.go()"> Retry </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else>
      <h2 class="primary--text">Edit Employee</h2>
      <v-card elevation="2" class="form-card px-sm-5 py-sm-5 my-sm-3 px-2 py-2">
        <v-row class="pt-5">
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="employee_no" :error-messages="empIdErrors" label="Employee ID *" required filled
              clearable prepend-inner-icon="mdi-card-account-details" @input="$v.employee_no.$touch()"
              @blur="$v.employee_no.$touch()"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="designation" :error-messages="designationErrors" label="Designation *" required
              filled clearable prepend-inner-icon="mdi-account-star" @input="$v.designation.$touch()"
              @blur="$v.designation.$touch()"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="email" label="Primary E-mail *" prepend-inner-icon="mdi-email" required readonly
              filled></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="secondary_email" :error-messages="secondaryEmailErrors" label="Secondary E-mail"
              prepend-inner-icon="mdi-email" filled clearable @input="$v.secondary_email.$touch()"
              @blur="$v.secondary_email.$touch()"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="first_name" :error-messages="fnameErrors" label="First Name *" required filled
              clearable prepend-inner-icon="mdi-account-edit" @input="$v.first_name.$touch()"
              @blur="$v.first_name.$touch()"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="last_name" :error-messages="lnameErrors" label="Last Name *" required filled
              clearable prepend-inner-icon="mdi-account-edit" @input="$v.last_name.$touch()"
              @blur="$v.last_name.$touch()"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="nick_name" :error-messages="nickNameErrors" :counter="10" label="Nick Name" filled
              clearable prepend-inner-icon="mdi-account-edit" @input="$v.nick_name.$touch()"
              @blur="$v.nick_name.$touch()"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="nic_no" :error-messages="nicErrors" label="NIC *" required filled clearable
              prepend-inner-icon="mdi-card-account-details" @input="$v.nic_no.$touch()" @blur="$v.nic_no.$touch()">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-dialog ref="joined_date_modal" v-model="joined_date_modal" :return-value.sync="date_of_joined" persistent
              width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="date_of_joined" label="Date of Joined *" prepend-inner-icon="mdi-calendar" dense
                  filled readonly v-bind="attrs" v-on="on" :error-messages="dateJoinedErrors"
                  @change="$v.date_of_joined.$touch()" @blur="$v.date_of_joined.$touch()"></v-text-field>
              </template>
              <v-date-picker v-model="date_of_joined" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="joined_date_modal = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.joined_date_modal.save(date_of_joined)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-dialog ref="birth_date_modal" v-model="birth_date_modal" :return-value.sync="date_of_birth" persistent
              width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="date_of_birth" label="Date of Birth *" prepend-inner-icon="mdi-calendar" dense
                  filled readonly v-bind="attrs" v-on="on" :error-messages="dateBirthErrors"
                  @change="$v.date_of_birth.$touch()" @blur="$v.date_of_birth.$touch()"></v-text-field>
              </template>
              <v-date-picker v-model="date_of_birth" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="birth_date_modal = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.birth_date_modal.save(date_of_birth)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="age_at_joining" label="Age at Joining" readonly filled
              prepend-inner-icon="mdi-map-marker"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-select v-model="blood_group" :items="blood" :error-messages="bloodSelectErrors" label="Blood Group *"
              required filled prepend-inner-icon="mdi-water" @change="$v.blood_group.$touch()"
              @blur="$v.blood_group.$touch()"></v-select>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-select v-model="gender" :items="sex" :error-messages="genderSelectErrors" label="Gender *" required
              filled prepend-inner-icon="mdi-gender-male" @change="$v.gender.$touch()" @blur="$v.gender.$touch()">
            </v-select>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="address" :error-messages="addressErrors" label="Address Line 1 *" required filled
              clearable prepend-inner-icon="mdi-map-marker" @input="$v.address.$touch()" @blur="$v.address.$touch()">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="address2" label="Address Line 2" filled clearable
              prepend-inner-icon="mdi-map-marker"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="city" :error-messages="cityErrors" label="City *" required filled clearable
              prepend-inner-icon="mdi-city" @input="$v.city.$touch()" @blur="$v.city.$touch()"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="state" :error-messages="stateErrors" label="State/Province *" required filled
              clearable prepend-inner-icon="mdi-airplane" @input="$v.state.$touch()" @blur="$v.state.$touch()">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="country" :error-messages="countryErrors" label="Country *" required filled clearable
              prepend-inner-icon="mdi-earth" @input="$v.country.$touch()" @blur="$v.country.$touch()"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="zip" :error-messages="zipErrors" label="Postal/ZIP Code *" required filled clearable
              prepend-inner-icon="mdi-mailbox" @input="$v.zip.$touch()" @blur="$v.zip.$touch()"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="mobile" :error-messages="mobileErrors" label="Primary Mobile Number *" required
              filled clearable prepend-inner-icon="mdi-cellphone" @input="$v.mobile.$touch()"
              @blur="$v.mobile.$touch()"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="secondary_mobile" label="Secondary Mobile Number" filled clearable
              prepend-inner-icon="mdi-cellphone"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="tele" :error-messages="teleErrors" label="Telephone Number"
              prepend-inner-icon="mdi-phone" filled clearable @input="$v.tele.$touch()" @blur="$v.tele.$touch()">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-select v-model="roleSelect" :items="roleItems" :error-messages="roleSelectErrors" label="Roles"
              item-text="title" item-value="id" required filled prepend-inner-icon="mdi-shield-account"
              @change="$v.roleSelect.$touch()" @blur="$v.roleSelect.$touch()"></v-select>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-select v-model="supervisorSelect" :items="supervisors" label="Immediate supervisor"
              item-text="first_name" item-value="user_id" required filled prepend-inner-icon="mdi-account"></v-select>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-dialog ref="left_date_modal" v-model="left_date_modal" :return-value.sync="date_of_left" persistent
              width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="date_of_left" label="Leaving Date" prepend-inner-icon="mdi-calendar" dense filled
                  clearable readonly v-bind="attrs" v-on="on" :error-messages="dateLeftErrors"
                  @change="$v.date_of_left.$touch()" @blur="$v.date_of_left.$touch()"></v-text-field>
              </template>
              <v-date-picker v-model="date_of_left" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="left_date_modal = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.left_date_modal.save(date_of_left)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>

        <div class="text-center ma-2">
          <v-btn color="primary" class="mr-4" @click="confirm"> submit </v-btn>

          <v-btn color="primary" link to="/employees/all"> cancel </v-btn>
        </div>
      </v-card>
      <v-dialog v-model="confirm_dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5"> Confirmation </v-card-title>
          <v-card-text>Please take a minute and check all the information before submit.
            &#8987;</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary lighten-1" text @click="confirm_dialog = false">
              Check again
            </v-btn>
            <v-btn color="primary darken-1" @click="submit" :loading="submit_loading" :disabled="submit_loading">
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </form>
</template>

<script>
const axios = require("axios").default;
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  email,
  numeric,
  minLength,
} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  validations: {
    nic_no: { required },
    date_of_birth: { required },
    date_of_joined: { required },
    secondary_email: { email },
    employee_no: { required },
    designation: { required },
    first_name: { required },
    last_name: { required },
    nick_name: { maxLength: maxLength(10) },
    address: { required },
    city: { required },
    state: { required },
    country: { required },
    zip: { required, numeric },
    mobile: {
      required,
      numeric,
      maxLength: maxLength(12),
      minLength: minLength(9),
    },
    tele: {
      numeric,
      maxLength: maxLength(12),
      minLength: minLength(9),
    },
    roleSelect: { required },
    gender: { required },
    blood_group: { required },
  },

  data: () => ({
    nonet: false,
    employee_no: null, //
    first_name: "",
    last_name: "",
    nick_name: "",
    email: "", //
    secondary_email: "", //
    sex: ["Male", "Female", "Other"],
    gender: null,
    blood_group: null,
    designation: null,
    date_of_joined: null,
    date_of_left: null,
    nic_no: null,
    date_of_birth: null,
    address: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    mobile: "",
    secondary_mobile: "",
    tele: "",
    roleSelect: null,
    roleItems: null,
    supervisorSelect: null,
    supervisors: null,
    snackbar: false,
    text: ``,
    timeout: 3000,
    joined_date_modal: false,
    left_date_modal: false,
    birth_date_modal: false,
    blood: ["O+", "O-", "A+", "A-", "B+", "B-", "AB+", "AB-"],

    confirm_dialog: false,
    loader: null,
    submit_loading: false,
  }),

  computed: {
    dateJoinedErrors() {
      const errors = [];
      if (!this.$v.date_of_joined.$dirty) return errors;
      !this.$v.date_of_joined.required && errors.push("Date is required");
      return errors;
    },
    dateLeftErrors() {
      const errors = [];
      // if (!this.$v.date_of_left.$dirty) return errors;
      this.date_of_joined > this.date_of_left &&
        errors.push(
          "Leaving date must be newer than the Date of Joined"
        );
      return errors;
    },
    age_at_joining() {
      let ageInMilliseconds =
        new Date(this.date_of_joined) - new Date(this.date_of_birth);
      return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365);
    },
    dateBirthErrors() {
      const errors = [];
      if (!this.$v.date_of_birth.$dirty) return errors;
      !this.$v.date_of_birth.required && errors.push("Date is required");
      return errors;
    },
    roleSelectErrors() {
      const errors = [];
      if (!this.$v.roleSelect.$dirty) return errors;
      !this.$v.roleSelect.required && errors.push("Role is required");
      return errors;
    },
    bloodSelectErrors() {
      const errors = [];
      if (!this.$v.blood_group.$dirty) return errors;
      !this.$v.blood_group.required && errors.push("Blood Group is required");
      return errors;
    },
    genderSelectErrors() {
      const errors = [];
      if (!this.$v.gender.$dirty) return errors;
      !this.$v.gender.required && errors.push("Gender is required");
      return errors;
    },
    empIdErrors() {
      const errors = [];
      if (!this.$v.employee_no.$dirty) return errors;
      !this.$v.employee_no.required && errors.push("Employee Id is required.");
      return errors;
    },
    nicErrors() {
      const errors = [];
      if (!this.$v.nic_no.$dirty) return errors;
      !this.$v.nic_no.required && errors.push("NIC is required.");
      return errors;
    },
    designationErrors() {
      const errors = [];
      if (!this.$v.designation.$dirty) return errors;
      !this.$v.designation.required &&
        errors.push("Designation Id is required.");
      return errors;
    },
    fnameErrors() {
      const errors = [];
      if (!this.$v.first_name.$dirty) return errors;
      !this.$v.first_name.required && errors.push("First name is required.");
      return errors;
    },
    lnameErrors() {
      const errors = [];
      if (!this.$v.last_name.$dirty) return errors;
      !this.$v.last_name.required && errors.push("Last name is required.");
      return errors;
    },
    nickNameErrors() {
      const errors = [];
      if (!this.$v.nick_name.$dirty) return errors;
      !this.$v.nick_name.maxLength &&
        errors.push("Nick name must be at most 10 characters long");
      return errors;
    },
    secondaryEmailErrors() {
      const errors = [];
      if (!this.$v.secondary_email.$dirty) return errors;
      !this.$v.secondary_email.email && errors.push("Must be a valid e-mail");
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.address.$dirty) return errors;
      !this.$v.address.required && errors.push("Address is required");
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      !this.$v.city.required && errors.push("City is required");
      return errors;
    },
    stateErrors() {
      const errors = [];
      if (!this.$v.state.$dirty) return errors;
      !this.$v.state.required && errors.push("State is required");
      return errors;
    },
    countryErrors() {
      const errors = [];
      if (!this.$v.country.$dirty) return errors;
      !this.$v.country.required && errors.push("Country is required");
      return errors;
    },
    zipErrors() {
      const errors = [];
      if (!this.$v.zip.$dirty) return errors;
      !this.$v.zip.required && errors.push("Postal/ZIP code is required");
      !this.$v.zip.numeric && errors.push("Should be a numeric value");
      return errors;
    },
    mobileErrors() {
      const errors = [];
      if (!this.$v.mobile.$dirty) return errors;
      !this.$v.mobile.numeric && errors.push("Should be a numeric value");
      !this.$v.mobile.required && errors.push("Mobile number is required");
      !this.$v.mobile.maxLength && errors.push("Must be a valid mobile number");
      !this.$v.mobile.minLength && errors.push("Must be a valid mobile number");
      return errors;
    },
    teleErrors() {
      const errors = [];
      if (!this.$v.tele.$dirty) return errors;
      !this.$v.tele.numeric && errors.push("Should be a numeric value");
      !this.$v.tele.maxLength &&
        errors.push("Must be a valid telephone number");
      !this.$v.tele.minLength &&
        errors.push("Must be a valid telephone number");
      return errors;
    },
  },

  methods: {
    confirm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.confirm_dialog = true;
      } else {
        this.snackbar = true;
        this.text = `Please fill out all required fields.`;
      }
    },

    submit() {
      const l = "submit_loading"; // button loader
      this[l] = !this[l]; // button loader
      axios
        .post(this.$backend_url + "update-employee", {
          employee_no: this.employee_no,
          emp_id: this.$route.params.id,
          secondary_email: this.secondary_email,
          first_name: this.first_name,
          last_name: this.last_name,
          nick_name: this.nick_name,
          gender: this.gender,
          designation: this.designation,
          date_of_joined: this.date_of_joined,
          left_date: this.date_of_left,
          age_at_joining: this.age_at_joining,
          nic_no: this.nic_no,
          address_1: this.address,
          address_2: this.address2,
          city: this.city,
          state: this.state,
          country: this.country,
          postal_no: this.zip,
          primary_mobile_no: this.mobile,
          secondary_mobile_no: this.secondary_mobile,
          date_of_birth: this.date_of_birth,
          blood_group: this.blood_group,
          fixed_no: this.tele,
          role: this.roleSelect,
          supervisor: this.supervisorSelect
        })
        .then((res) => {
          this[l] = false; // button loader
          if (res.data.success === true) {
            this.snackbar = true;
            this.text = `${this.first_name} ${this.last_name} has been successfully updated.`;
            this.confirm_dialog = false;
          }
          if (res.data.success === false) {
            console.log(res)
            this.snackbar = true;
            this.text = `${this.first_name} ${this.last_name} already exists with the same email!!!`;
          }
        })
        .catch((res) => {
          this[l] = false; // button loader
          console.log("Errors:", res);
          this.snackbar = true;
          this.text = `Error occured while sending data.`;
        });
    },
  },
  mounted: function () {
    if (
      !(
        JSON.parse(localStorage.imso_user).role == 1 ||
        JSON.parse(localStorage.imso_user).role == 5
      )
    ) {
      this.$router.push("/");
    }
    axios
      .get(this.$backend_url + "roles", {
        params: {
          remember_token: JSON.parse(localStorage.imso_user)["remember_token"],
          email: JSON.parse(localStorage.imso_user)["email"],
        },
      })
      .then((response) => {
        if (response.data.success) {
          this.roleItems = response.data.role.slice(1, 5);
        }
        this.nonet = false;
      })
      .catch((res) => {
        console.log("Errors:", res);
        this.nonet = true;
        this.snackbar = true;
        this.text = `No Internet`;
      });

    axios
      .get(this.$backend_url + "supervisors")
      .then((response) => {
        if (response.data.success) {
          this.supervisors = response.data.supervisors;
        }
        this.nonet = false;
      })
      .catch((res) => {
        console.log("Errors:", res);
        this.nonet = true;
        this.snackbar = true;
        this.text = `No Internet`;
      });

    axios
      .get(this.$backend_url + "employee-detail", {
        params: {
          remember_token: JSON.parse(localStorage.imso_user)["remember_token"],
          email: JSON.parse(localStorage.imso_user)["email"],
          emp_id: this.$route.params.id,
        },
      })
      .then((response) => {
        if (response.data.success) {
          this.employee_no = response.data.employee_detail.employee_no;
          this.first_name = response.data.employee_detail.first_name;
          this.last_name = response.data.employee_detail.last_name;
          this.nick_name = response.data.employee_detail.nick_name;
          this.email = response.data.employee_detail.primary_email;
          this.secondary_email = response.data.employee_detail.secondary_email;
          this.gender = response.data.employee_detail.gender;
          this.blood_group = response.data.employee_detail.blood_group;
          this.designation = response.data.employee_detail.designation;
          this.date_of_joined = response.data.employee_detail.date_of_joined;
          this.date_of_left = response.data.employee_detail.left_date;
          this.nic_no = response.data.employee_detail.nic_no;
          this.date_of_birth = response.data.employee_detail.date_of_birth;
          this.address = response.data.employee_detail.address_1;
          this.address2 = response.data.employee_detail.address_2;
          this.city = response.data.employee_detail.city;
          this.state = response.data.employee_detail.state;
          this.country = response.data.employee_detail.country;
          this.zip = response.data.employee_detail.postal_code;
          this.mobile = response.data.employee_detail.primary_mobile_no;
          this.secondary_mobile =
            response.data.employee_detail.secondary_mobile_no;
          this.tele = response.data.employee_detail.fixed_no;
          this.roleSelect = parseInt(response.data.employee_detail.role);
          this.supervisorSelect = response.data.employee_detail.immediate_supervisor_id;
        }
        this.nonet = false;
      })
      .catch((res) => {
        console.log("Errors:", res);
        this.nonet = true;
        this.snackbar = true;
        this.text = `No Internet`;
      });
  },
};
</script>

<style>
.ne-main {
  padding: 20px;
  margin-left: 50px;
  width: calc(100vw - 60px);
  min-height: calc(100vh - 200px);
}

@media only screen and (max-width: 960px) {
  .ne-main {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .v-card.form-card {
    box-shadow: none !important;
  }

  .ne-main {
    padding: 20px;
    margin-left: 0px;
    width: 100vw;
  }
}
</style>